.navStyle {
    box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
    background: white;
    position: fixed!important;
    width: 100%;
    z-index: 99!important;
    transition: 0.5s!important;
    font-family: 'Poppins', sans-serif;
}
.navDefault {
    transition: 0.5s!important;
    padding: 25px 0 40px 0!important;
    margin-bottom: 2rem;
}

.mainNav .nav-link {
    color: #070120!important;
    margin: 0.5rem 1.2rem!important;
    font-weight: 500!important;
    transition: 0.3s;
    font-size: 1.1rem;
    font-family: 'Poppins', sans-serif;
}
.mainNav .nav-link:after {
    content: '';
    display: block;
    margin: auto;
    height: 3px;
    width: 0px;
    background: transparent;
    transition: width .5s ease, background-color .5s ease;
}
.mainNav .nav-link:hover:after {
    width: 100%;
    background: #4C25F5;
}

.navBrn {
    font-weight: 700;
    font-size: 1.5rem;
}
.brnIcon,
.navHighlight {
    color: #7456F7;;
}
.brnIcon {
    font-weight: 800;
    font-size: 1.6rem
}

.loginBtn {
    outline: none;
    border: none;
    border-radius: 50px;
    font-weight: 500;
    color:#fff;
    background: #7355F7;
    padding: 0.5rem 1.5rem;
    margin: 0.5rem 1rem;
    transition: 0.4s;
}
.loginBtn:hover { 
    background : #4B24F5
}
.navActiveClass{
    color:"red";
    font-weight:900;
}

@media (max-width: 981px) {
    .navDefault {
        background: white;
        padding: 0.8rem 0.5rem!important;
        width: 100%;
        z-index: 99!important;
        transition: 0.5s!important;
        text-align: center;
    }
    .navStyle{
        text-align: center;
    }
}