#testimonial {
    padding: 7rem 0 6rem;
    background: #F8F5FF;
}
.review{
    box-shadow: 1px 0 20px rgb(0 0 0 / 10%);
    padding: 1rem;
    border-radius: 0.3rem;
    margin: 5rem 0 1rem 0;
    text-align: center;
    background: #fff;
    max-width: 550px;
}
.review > img {
    height: 90px;
    width: 90px;
    border-radius: 50%;
    margin-top: -4rem;
    border: 5px solid #fff;
}
.review > .testimonialName {
    font-weight: 600;
    font-size: 22px;
    font-family: sans-serif;
    color: #000;
    line-height: 26px;
    margin: 0.8rem 0 0.4rem 0;
}
.review > .testimonialAddress {
    font-size: 15px;
    color: #7053F1;
}
.review > p {
    color: #777;
    margin-top: 0.8rem;
    font-size: 1rem;
    font-weight: 400;
    font-family: sans-serif;
}
.rate {
    color:#FFBE5B;
}
:root {
    --swiper-theme-color: #7053F1!important;
}