#statusBtn {
    border: none;
    outline: none;
}
#pending {
    color: white;
    background: rgb(255 78 96);
}
#pending:hover{
    background: rgb(228 72 88);
}
#ongoing {
    color: white;
    background: rgb(73 146 255);
}
#ongoing:hover{
    background: #4185e2;
}
#done {
    color: white;
    background: rgb(31 204 123);
}
#done:hover{
    background: rgb(31 177 109);
}
.dropdown-menu{
    padding: 1px!important;
    border: 1px solid black!important;
}

#dropdown-basic-button .btn {
    border: none;
    box-shadow: none;
}